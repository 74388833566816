export const DESC: any = [
  [
    { label: '4000', txt: '' },
    { label: '6000', txt: '推荐' }
  ],
  [
    { label: '10000', txt: '' },
    { label: '15000', txt: '' },
    { label: '20000', txt: '推荐' },
    { label: '40000', txt: '' }
  ],
  [
    { label: '6000', txt: '' },
    { label: '8000', txt: '' },
    { label: '10000', txt: '推荐' }
  ]
]

export const undergraduateTreeData = [
  {
    title: '哲学',
    key: '01',
    children: [
      {
        title1: '哲学',
        title: '哲学类',
        key: 101
      }
    ]
  },
  {
    title: '经济学',
    key: '02',
    children: [
      {
        title1: '经济学',
        title: '经济学类',
        key: 201
      },
      {
        title1: '经济学',
        title: '财政学类',
        key: 202
      },
      {
        title1: '经济学',
        title: '金融学类',
        key: 203
      },
      {
        title1: '经济学',
        title: '经济与贸易类',
        key: 204
      }
    ]
  },
  {
    title: '法学',
    key: '03',
    children: [
      {
        title1: '法学',
        title: '法学类',
        key: 301
      },
      {
        title1: '法学',
        title: '政治学类',
        key: 302
      },
      {
        title1: '法学',
        title: '社会学类',
        key: 303
      },
      {
        title1: '法学',
        title: '民族学类',
        key: 304
      },
      {
        title1: '法学',
        title: '马克思主义理论类',
        key: 305
      },
      {
        title1: '法学',
        title: '公安学类',
        key: 306
      }
    ]
  },
  {
    title: '教育学',
    key: '04',
    children: [
      {
        title1: '教育学',
        title: '教育学类',
        key: 401
      },
      {
        title1: '教育学',
        title: '体育学类',
        key: 402
      }
    ]
  },
  {
    title: '文学',
    key: '05',
    children: [
      {
        title1: '文学',
        title: '中国语言文学类',
        key: 501
      },
      {
        title1: '文学',
        title: '外国语言文学',
        key: 502
      },
      {
        title1: '文学',
        title: '新闻传播学类',
        key: 503
      }
    ]
  },
  {
    title: '历史学',
    key: '06',
    children: [
      {
        title1: '历史学',
        title: '历史学类',
        key: 601
      }
    ]
  },
  {
    title: '理学',
    key: '07',
    children: [
      {
        title1: '理学',
        title: '数学类',
        key: 701
      },
      {
        title1: '理学',
        title: '物理学类',
        key: 702
      },
      {
        title1: '理学',
        title: '化学类',
        key: 703
      },
      {
        title1: '理学',
        title: '天文学类',
        key: 704
      },
      {
        title1: '理学',
        title: '地理科学类',
        key: 705
      },
      {
        title1: '理学',
        title: '大气科学类',
        key: 706
      },
      {
        title1: '理学',
        title: '海洋科学类',
        key: 707
      },
      {
        title1: '理学',
        title: '地球物理学类',
        key: 708
      },
      {
        title1: '理学',
        title: '地质学类',
        key: 709
      },
      {
        title1: '理学',
        title: '生物科学类',
        key: 710
      },
      {
        title1: '理学',
        title: '心理学类',
        key: 711
      },
      {
        title1: '理学',
        title: '统计学类',
        key: 712
      }
    ]
  },
  {
    title: '工学',
    key: '08',
    children: [
      {
        title1: '工学',
        title: '力学类',
        key: 801
      },
      {
        title1: '工学',
        title: '机械类',
        key: 802
      },
      {
        title1: '工学',
        title: '仪器类',
        key: 803
      },
      {
        title1: '工学',
        title: '材料类',
        key: 804
      },
      {
        title1: '工学',
        title: '能源动力类',
        key: 805
      },
      {
        title1: '工学',
        title: '电气类',
        key: 806
      },
      {
        title1: '工学',
        title: '电子信息类',
        key: 807
      },
      {
        title1: '工学',
        title: '自动化类',
        key: 808
      },
      {
        title1: '工学',
        title: '计算机类',
        key: 809
      },
      {
        title1: '工学',
        title: '土木类',
        key: 810
      },
      {
        title1: '工学',
        title: '水利类',
        key: 811
      },
      {
        title1: '工学',
        title: '测绘类',
        key: 812
      },
      {
        title1: '工学',
        title: '化工与制药类',
        key: 813
      },
      {
        title1: '工学',
        title: '地质类',
        key: 814
      },
      {
        title1: '工学',
        title: '矿业类',
        key: 815
      },
      {
        title1: '工学',
        title: '纺织类',
        key: 816
      },
      {
        title1: '工学',
        title: '轻工类',
        key: 817
      },
      {
        title1: '工学',
        title: '交通运输类',
        key: 818
      },
      {
        title1: '工学',
        title: '海洋工程类',
        key: 819
      },
      {
        title1: '工学',
        title: '航空航天类',
        key: 820
      },
      {
        title1: '工学',
        title: '兵器类',
        key: 821
      },
      {
        title1: '工学',
        title: '核工程类',
        key: 822
      },
      {
        title1: '工学',
        title: '农业工程类',
        key: 823
      },
      {
        title1: '工学',
        title: '林业工程类',
        key: 824
      },
      {
        title1: '工学',
        title: '环境科学与工程类',
        key: 825
      },
      {
        title1: '工学',
        title: '生物医学工程类',
        key: 826
      },
      {
        title1: '工学',
        title: '食品科学与工程类',
        key: 827
      },
      {
        title1: '工学',
        title: '建筑类',
        key: 828
      },
      {
        title1: '工学',
        title: '安全科学与工程类',
        key: 829
      },
      {
        title1: '工学',
        title: '生物工程类',
        key: 830
      },
      {
        title1: '工学',
        title: '公安技术类',
        key: 831
      },
      {
        title1: '工学',
        title: '交叉工程类',
        key: 832
      }
    ]
  },
  {
    title: '农学',
    key: '09',
    children: [
      {
        title1: '农学',
        title: '植物生产类',
        key: 901
      },
      {
        title1: '农学',
        title: '自然保护与环境生态类',
        key: 902
      },
      {
        title1: '农学',
        title: '动物生产类',
        key: 903
      },
      {
        title1: '农学',
        title: '动物医学类',
        key: 904
      },
      {
        title1: '农学',
        title: '林学类',
        key: 905
      },
      {
        title1: '农学',
        title: '水产类',
        key: 906
      },
      {
        title1: '农学',
        title: '草学类',
        key: 907
      }
    ]
  },
  {
    title: '医学',
    key: '10',
    children: [
      {
        title1: '医学',
        title: '基础医学类',
        key: 1001
      },
      {
        title1: '医学',
        title: '临床医学类',
        key: 1002
      },
      {
        title1: '医学',
        title: '口腔医学类',
        key: 1003
      },
      {
        title1: '医学',
        title: '公共卫生与预防医学类',
        key: 1004
      },
      {
        title1: '医学',
        title: '中医学类',
        key: 1005
      },
      {
        title1: '医学',
        title: '中西医结合类',
        key: 1006
      },
      {
        title1: '医学',
        title: '药学类',
        key: 1007
      },
      {
        title1: '医学',
        title: '中药学类',
        key: 1008
      },
      {
        title1: '医学',
        title: '法医学类',
        key: 1009
      },
      {
        title1: '医学',
        title: '医学技术类',
        key: 1010
      },
      {
        title1: '医学',
        title: '护理学类',
        key: 1011
      }
    ]
  },
  {
    title: '管理学',
    key: '12',
    children: [
      {
        title1: '管理学',
        title: '管理科学与工程类',
        key: 1201
      },
      {
        title1: '管理学',
        title: '工商管理类',
        key: 1202
      },
      {
        title1: '管理学',
        title: '农业经济管理类',
        key: 1203
      },
      {
        title1: '管理学',
        title: '公共管理类',
        key: 1204
      },
      {
        title1: '管理学',
        title: '图书情报与档案管理类',
        key: 1205
      },
      {
        title1: '管理学',
        title: '物流管理与工程类',
        key: 1206
      },
      {
        title1: '管理学',
        title: '工业工程类',
        key: 1207
      },
      {
        title1: '管理学',
        title: '电子商务类',
        key: 1208
      },
      {
        title1: '管理学',
        title: '旅游管理类',
        key: 1209
      }
    ]
  },
  {
    title: '艺术学',
    key: '13',
    children: [
      {
        title1: '艺术学',
        title: '艺术学理论类',
        key: 1301
      },
      {
        title1: '艺术学',
        title: '音乐与舞蹈学类',
        key: 1302
      },
      {
        title1: '艺术学',
        title: '戏剧与影视学类',
        key: 1303
      },
      {
        title1: '艺术学',
        title: '美术学类',
        key: 1304
      },
      {
        title1: '艺术学',
        title: '设计学类',
        key: 1305
      }
    ]
  }
]

export const juniorCollegeTreeData = [
  {
    title: '农林牧渔大类',
    key: '01',
    children: [
      {
        title1: '农林牧渔类',
        title: '农业类',
        key: 101
      },
      {
        title1: '农林牧渔类',
        title: '林业类类',
        key: 102
      },
      {
        title1: '农林牧渔类',
        title: '畜牧业类',
        key: 103
      },
      {
        title1: '农林牧渔类',
        title: '渔业类',
        key: 104
      }
    ]
  },
  {
    title: '资源环境与安全大类',
    key: '02',
    children: [
      {
        title1: '资源环境与安全类',
        title: '资源勘查类',
        key: 201
      },
      {
        title1: '资源环境与安全类',
        title: '地质类',
        key: 202
      },
      {
        title1: '资源环境与安全类',
        title: '测绘地理信息类',
        key: 203
      },
      {
        title1: '资源环境与安全类',
        title: '石油与天然气类',
        key: 204
      },
      {
        title1: '资源环境与安全类',
        title: '煤炭类',
        key: 205
      },
      {
        title1: '资源环境与安全类',
        title: '金属与非金属矿类',
        key: 206
      },
      {
        title1: '资源环境与安全类',
        title: '气象类',
        key: 207
      },
      {
        title1: '资源环境与安全类',
        title: '环境保护类',
        key: 208
      },
      {
        title1: '资源环境与安全类',
        title: '安全类',
        key: 209
      }
    ]
  },
  {
    title: '能源动力与材料大类',
    key: '03',
    children: [
      {
        title1: '能源动力与材料大类',
        title: '电力技术类',
        key: 301
      },
      {
        title1: '能源动力与材料大类',
        title: '热能与发电工程类',
        key: 302
      },
      {
        title1: '能源动力与材料大类',
        title: '新能源发电工程类',
        key: 303
      },
      {
        title1: '能源动力与材料大类',
        title: '黑色金属材料类',
        key: 304
      },
      {
        title1: '能源动力与材料大类',
        title: '有色金属材料类',
        key: 305
      },
      {
        title1: '能源动力与材料大类',
        title: '非金属材料类',
        key: 306
      },
      {
        title1: '能源动力与材料大类',
        title: '建筑材料类',
        key: 307
      }
    ]
  },
  {
    title: '土木建筑大类',
    key: '04',
    children: [
      {
        title1: '土木建筑大类',
        title: '建筑设计类',
        key: 401
      },
      {
        title1: '土木建筑大类',
        title: '城乡规划与管理类',
        key: 402
      },
      {
        title1: '土木建筑大类',
        title: '土建施工类',
        key: 403
      },
      {
        title1: '土木建筑大类',
        title: '建筑设备类',
        key: 404
      },
      {
        title1: '土木建筑大类',
        title: '建设工程管理类',
        key: 405
      },
      {
        title1: '土木建筑大类',
        title: '市政工程类',
        key: 406
      },
      {
        title1: '土木建筑大类',
        title: '房地产类',
        key: 407
      }
    ]
  },
  {
    title: '水利大类',
    key: '05',
    children: [
      {
        title1: '水利大类',
        title: '水文水资源类',
        key: 501
      },
      {
        title1: '水利大类',
        title: '水利工程与管理类',
        key: 502
      },
      {
        title1: '水利大类',
        title: '水利水电设备类',
        key: 503
      },
      {
        title1: '水利大类',
        title: '水土保持与水环境类',
        key: 504
      }
    ]
  },
  {
    title: '装备制造大类',
    key: '06',
    children: [
      {
        title1: '装备制造大类',
        title: '机械设计制造类',
        key: 601
      },
      {
        title1: '装备制造大类',
        title: '机电设备类',
        key: 602
      },
      {
        title1: '装备制造大类',
        title: '自动化类',
        key: 603
      },
      {
        title1: '装备制造大类',
        title: '轨道装备类',
        key: 604
      },
      {
        title1: '装备制造大类',
        title: '船舶与海洋工程装备类',
        key: 605
      },
      {
        title1: '装备制造大类',
        title: '航空装备类',
        key: 606
      },
      {
        title1: '装备制造大类',
        title: '汽车制造类',
        key: 607
      }
    ]
  },
  {
    title: '生物与化工大类',
    key: '07',
    children: [
      {
        title1: '生物与化工大类',
        title: '生物技术类',
        key: 701
      },
      {
        title1: '生物与化工大类',
        title: '化工技术类',
        key: 702
      }
    ]
  },
  {
    title: '轻工纺织大类',
    key: '08',
    children: [
      {
        title1: '轻工纺织大类',
        title: '轻化工类',
        key: 801
      },
      {
        title1: '轻工纺织大类',
        title: '包装类',
        key: 802
      },
      {
        title1: '轻工纺织大类',
        title: '印刷类',
        key: 803
      },
      {
        title1: '轻工纺织大类',
        title: '纺织服装类',
        key: 804
      }
    ]
  },
  {
    title: '食品药品与粮食大类',
    key: '09',
    children: [
      {
        title1: '食品药品与粮食大类',
        title: '食品类',
        key: 901
      },
      {
        title1: '食品药品与粮食大类',
        title: '药品与医疗器械类',
        key: 902
      },
      {
        title1: '食品药品与粮食大类',
        title: '粮食类',
        key: 903
      }
    ]
  },
  {
    title: '交通运输大类',
    key: '10',
    children: [
      {
        title1: '交通运输大类',
        title: '铁道运输类',
        key: 1001
      },
      {
        title1: '交通运输大类',
        title: '水上运输类',
        key: 1002
      },
      {
        title1: '交通运输大类',
        title: '航空运输类',
        key: 1003
      },
      {
        title1: '交通运输大类',
        title: '管道运输类',
        key: 1004
      },
      {
        title1: '交通运输大类',
        title: '管道轨道交通类',
        key: 1005
      },
      {
        title1: '交通运输大类',
        title: '邮政类',
        key: 1006
      }
    ]
  },
  {
    title: '电子信息大类',
    key: '11',
    children: [
      {
        title1: '电子信息大类',
        title: '电子信息类',
        key: 1101
      },
      {
        title1: '电子信息大类',
        title: '计算机类',
        key: 1102
      },
      {
        title1: '电子信息大类',
        title: '通信类',
        key: 1103
      },
      {
        title1: '电子信息大类',
        title: '集成电路类',
        key: 1104
      }
    ]
  },
  {
    title: '医药卫生大类',
    key: '12',
    children: [
      {
        title1: '医药卫生大类',
        title: '临床医学类',
        key: 1201
      },
      {
        title1: '医药卫生大类',
        title: '护理类',
        key: 1202
      },
      {
        title1: '医药卫生大类',
        title: '药学类',
        key: 1203
      },
      {
        title1: '医药卫生大类',
        title: '中医药类',
        key: 1204
      },
      {
        title1: '医药卫生大类',
        title: '医学技术类',
        key: 1205
      },
      {
        title1: '医药卫生大类',
        title: '康复治疗类',
        key: 1206
      },
      {
        title1: '医药卫生大类',
        title: '公共卫生与卫生管理类',
        key: 1207
      },
      {
        title1: '医药卫生大类',
        title: '健康管理与促进类',
        key: 1208
      },
      {
        title1: '医药卫生大类',
        title: '眼视光类',
        key: 1209
      }
    ]
  },
  {
    title: '财经商贸大类',
    key: '13',
    children: [
      {
        title1: '财经商贸大类',
        title: '财政税务类',
        key: 1301
      },
      {
        title1: '财经商贸大类',
        title: '金融类',
        key: 1302
      },
      {
        title1: '财经商贸大类',
        title: '财务会计类',
        key: 1303
      },
      {
        title1: '财经商贸大类',
        title: '统计类',
        key: 1304
      },
      {
        title1: '财经商贸大类',
        title: '经济贸易类',
        key: 1305
      },
      {
        title1: '财经商贸大类',
        title: '工商管理类',
        key: 1306
      },
      {
        title1: '财经商贸大类',
        title: '电子商务类',
        key: 1307
      },
      {
        title1: '财经商贸大类',
        title: '物流类',
        key: 1308
      }
    ]
  },
  {
    title: '旅游大类',
    key: '14',
    children: [
      {
        title1: '旅游大类',
        title: '旅游类',
        key: 1401
      },
      {
        title1: '旅游大类',
        title: '餐饮类',
        key: 1402
      }
    ]
  },
  {
    title: '文化艺术大类',
    key: '15',
    children: [
      {
        title1: '文化艺术大类',
        title: '艺术设计类',
        key: 1501
      },
      {
        title1: '文化艺术大类',
        title: '表演艺术类',
        key: 1502
      },
      {
        title1: '文化艺术大类',
        title: '民族艺术类',
        key: 1503
      },
      {
        title1: '文化艺术大类',
        title: '文化服务类',
        key: 1504
      }
    ]
  },
  {
    title: '新闻传播大类',
    key: '16',
    children: [
      {
        title1: '新闻传播大类',
        title: '新闻出版类',
        key: 1601
      },
      {
        title1: '新闻传播大类',
        title: '广播影视类',
        key: 1602
      }
    ]
  },
  {
    title: '教育与体育大类',
    key: '17',
    children: [
      {
        title1: '教育与体育大类',
        title: '教育类',
        key: 1701
      },
      {
        title1: '教育与体育大类',
        title: '语言类',
        key: 1702
      },
      {
        title1: '教育与体育大类',
        title: '体育类',
        key: 1703
      }
    ]
  },
  {
    title: '公安与司法大类',
    key: '18',
    children: [
      {
        title1: '公安与司法大类',
        title: '公安管理类',
        key: 1801
      },
      {
        title1: '公安与司法大类',
        title: '侦查类',
        key: 1802
      },
      {
        title1: '公安与司法大类',
        title: '法律实务类',
        key: 1803
      },
      {
        title1: '公安与司法大类',
        title: '司法技术类',
        key: 1804
      },
      {
        title1: '公安与司法大类',
        title: '安全防范类',
        key: 1805
      }
    ]
  },
  {
    title: '公共管理与服务大类',
    key: '19',
    children: [
      {
        title1: '公共管理与服务大类',
        title: '公共事业类',
        key: 1901
      },
      {
        title1: '公共管理与服务大类',
        title: '公共管理类',
        key: 1902
      },
      {
        title1: '公共管理与服务大类',
        title: '公共服务类',
        key: 1903
      }
    ]
  }
]
