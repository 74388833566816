import { memo, useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import './BaseCountDown.less'

const BaseCountDown = (props: any) => {
  const [timeLeft, setTimeLeft] = useState('')
  // const [day, setDay] = useState(0)
  const days = useRef<any>([])
  const formatTime = (num: any, digits = 2) => String(num).padStart(digits, '0')

  useEffect(() => {
    // const targetDate: any = new Date(2025, 1, 18) // 到期 2 月 17 日 24 时 0 分 0 秒
    // 设置起始时间为 2025 年 2 月 15 日零时
    const baseDate = new Date(2025, 1, 18) // 注意：月份是从0开始的，1表示2月
    baseDate.setHours(0, 0, 0, 0) // 设置为零时零分零秒
    // 获取下一个目标时间
    const getNextTargetDate = () => {
      const now = new Date()
      const targetDate = new Date(baseDate)

      // 循环判断，直到目标时间大于当前时间
      while (targetDate <= now) {
        targetDate.setDate(targetDate.getDate() + 3) // 每次加3天，进入下一个周期
      }

      const startDate = new Date(targetDate)
      startDate.setDate(startDate.getDate() - 3) // 当前周期的起始时间
      const endDate = new Date(targetDate)
      endDate.setDate(endDate.getDate() - 1) // 当前周期的起始时间
      const s = dayjs(startDate).format('YYYY-MM-DD')
      const e = dayjs(endDate).format('YYYY-MM-DD')
      if (days.current[0] != s) {
        days.current.push(s, e)
        // console.log('targetDate', days.current)
        props.onSetDate && props.onSetDate(days.current)
      }

      return targetDate
    }

    const updateCountdown = () => {
      const now: any = new Date()
      const targetDate: any = getNextTargetDate()
      const diff = targetDate - now
      // if (diff <= 0) {
      // setTimeLeft('活动结束')
      //   props.onCountDownEnd && props.onCountDownEnd()
      //   return
      // }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24)) // 天数
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24) // 小时
      const minutes = Math.floor((diff / (1000 * 60)) % 60) // 分钟
      const seconds = Math.floor((diff / 1000) % 60) // 秒
      const milliseconds = Math.floor((diff % 1000) / 100) // 毫秒，保留一位
      // setDay(days)
      setTimeLeft(`${days}天 ${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}.${milliseconds}`)
    }

    const timer = setInterval(updateCountdown, 100) // 每 100ms 更新一次
    updateCountdown() // 初始化时调用一次

    return () => clearInterval(timer) // 组件卸载时清除定时器
  }, [])
  //  style={{ display: day > 9 ? 'none' : 'flex' }}
  return (
    <div className={`BaseCountDown-contianer ${props.className}`}>
      {timeLeft == '活动结束' ? (
        <span className='center'>活动结束</span>
      ) : (
        <>
          <span className='left'>全年最低价 仅剩 </span>
          <span className='right'>{timeLeft}</span>
        </>
      )}
    </div>
  )
}

export default memo(BaseCountDown)
