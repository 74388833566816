// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useState, useEffect, useReducer, memo, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'
import SubRoute from './router/SubRoute'
import { ThemeContext, appReducer, initData, CHAT_DEFAULT } from '@/store/store'
import { getUserInfoApi, expendApi } from '@/server/commonFetch'
import './assets/iconfont/iconfont.js'
import mobileBgPng from '@/assets/images/mobile-bg.jpg'
import CommonFunc from '@/utils/common-func'
const unityUrl = process.env.VITE_NAME
// import eruda from 'eruda'
// if (process.env.VITE_NAME === 'chatdev') {
// eruda.init()
// }
const pxWidth = 1440
const rootFontSizeRate = 100
const App = () => {
  const [loading, setLoading] = useState(true)
  const [state, dispatch] = useReducer(appReducer, initData)
  const [mobileBg, setMobileBg] = useState(false)
  // const browserType = CommonFunc.detectBrowser()
  const isMobilePage = unityUrl + '/mobileActivity'
  useEffect(() => {
    if (CommonFunc.isMobileBrowser()) {
      setLoading(false)
      setMobileBg(true)
      return
    }
    const aiPaperData = localStorage.getItem('ai-paper-data') || '[]'
    const lowChongData = localStorage.getItem('low-chong-data') || '[]'
    const lowAIGCData = localStorage.getItem('low-aigc-data') || '[]'
    const creatPPTData = localStorage.getItem('creat-ppt-data') || '[]'
    const openReportData = localStorage.getItem('open-report-data') || '[]'
    const modelTab = localStorage.getItem('modelTab')
    if (modelTab != undefined) {
      dispatch({ type: CHAT_DEFAULT, payload: { modelTab: modelTab } })
    }
    if (openReportData) {
      const data = JSON.parse(openReportData)
      dispatch({ type: CHAT_DEFAULT, payload: { openReportData: [...data] } })
    }
    if (openReportData) {
      const data = JSON.parse(openReportData)
      dispatch({ type: CHAT_DEFAULT, payload: { openReportData: [...data] } })
    }
    if (aiPaperData) {
      const data = JSON.parse(aiPaperData)
      dispatch({ type: CHAT_DEFAULT, payload: { aiPaperData: [...data] } })
    }
    if (lowChongData) {
      const data = JSON.parse(lowChongData)
      dispatch({ type: CHAT_DEFAULT, payload: { lowChongData: [...data] } })
    }
    if (lowAIGCData) {
      const data = JSON.parse(lowAIGCData)
      dispatch({ type: CHAT_DEFAULT, payload: { lowAIGCData: [...data] } })
    }
    if (creatPPTData) {
      const data = JSON.parse(creatPPTData)
      dispatch({ type: CHAT_DEFAULT, payload: { creatPPTData: [...data] } })
    }
    if (!localStorage.getItem('uniToken') && location.pathname !== '/') {
      location.href = '/'
    }
    setHtmlFontSize()
    getUserInfo()
    getFail()
    window.onresize = setHtmlFontSize
  }, [])
  const getFail = async () => {
    const reportWordsFial = localStorage.getItem('reportWordsFial') || '{}'
    if (reportWordsFial != '{}') {
      const params = JSON.parse(reportWordsFial)
      expendApi({ dispatch, CHAT_DEFAULT, words: params.words, step: params.function, type: '不提示' })
    }
  }

  const setHtmlFontSize = () => {
    const html = document.documentElement
    const clientWidth = html.clientWidth
    let fontSize = 0
    if (location.pathname === isMobilePage) {
      fontSize = rootFontSizeRate * (clientWidth / 375)
      html.style.fontSize = `${fontSize}px`
    } else {
      if (clientWidth > pxWidth) {
        fontSize = rootFontSizeRate * (pxWidth / pxWidth)
        html.style.fontSize = `${fontSize}px`
      } else {
        fontSize = rootFontSizeRate * (clientWidth / pxWidth)
        html.style.fontSize = `${fontSize}px`
      }
    }
    dispatch({ type: CHAT_DEFAULT, payload: { clientInfo: { width: html.clientWidth, height: html.clientHeight, fontSize } } })
    setLoading(false)
    setMobileBg(false)
    if (CommonFunc.isMobileBrowser()) {
      setMobileBg(true)
    }
  }
  const getUserInfo = () => getUserInfoApi({ dispatch, CHAT_DEFAULT })
  return (
    <ThemeContext.Provider value={{ ...state, dispatch }}>
      <BrowserRouter>
        {!loading &&
          (mobileBg ? (
            <div className='mobile-bg'>
              <img style={{ width: '100%', height: '100%' }} src={mobileBgPng}></img>
            </div>
          ) : (
            <SubRoute />
          ))}
      </BrowserRouter>
    </ThemeContext.Provider>
  )
}

export default memo(App)
