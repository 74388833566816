import { memo, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd'
import ExplorationGroup from '@/assets/images/ExplorationGroup.png'
import './JoinGroup.less'
const JoinGroup = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) props.onOk()
  }
  const handleCancel = () => {
    if (props.onCancel) props.onCancel()
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} joinGroup-modal-container`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title=''
      footer={null}
      centered
    >
      <img src={ExplorationGroup} />
      <span className='sp1'>进群后请查看群公告</span>
      <span className='sp2'>加官方微信社群</span>
      <span className='sp3'>免费领5000体验字数</span>
    </Modal>
  )
}

export default memo(withRouter(JoinGroup))
