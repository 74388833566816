import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import CommonFunc from '@/utils/common-func'
import { message } from 'antd'
declare const window: any
window.zishuTimer = null
export const expendApi = async ({ dispatch, CHAT_DEFAULT, words, step, onSuccess, onError, type = '' }: any) => {
  const params = {
    unitoken: localStorage.getItem('uniToken'),
    words: words == 0 ? '00' : words,
    function: step
  }
  try {
    const res: any = await http('post', apiGroup.expend, params)
    if (res.status && res.data) {
      clearTimeout(window.zishuTimer)
      window.zishuTimer = null
      localStorage.removeItem('reportWordsFial')
      if (type == '字数耗尽') {
        message.info('字数已耗尽，请充值')
      } else if (type == '不提示') {
        console.log(1111)
      } else {
        if (words > 0) {
          message.info('功能：' + step + '，' + '消耗字数：' + words)
        }
      }
      getUserInfoApi({ dispatch, CHAT_DEFAULT, onSuccess, onError })
    } else {
      if (onError) onError(res.msg)
      timerCount(dispatch, CHAT_DEFAULT, params, onSuccess, onError)
    }
  } catch (err) {
    if (onError) onError(err)
    timerCount(dispatch, CHAT_DEFAULT, params, onSuccess, onError)
  }
}
const timerCount = (dispatch: any, CHAT_DEFAULT: any, params: any, onSuccess: any, onError: any) => {
  clearTimeout(window.zishuTimer)
  localStorage.setItem('reportWordsFial', JSON.stringify(params))
  window.zishuTimer = setTimeout(() => {
    localStorage.setItem('reportWordsFial', JSON.stringify(params))
    const reportWordsFial = localStorage.getItem('reportWordsFial') || '{}'
    if (reportWordsFial != '{}') {
      expendApi({
        dispatch,
        CHAT_DEFAULT,
        words: JSON.parse(reportWordsFial).words,
        step: JSON.parse(reportWordsFial).function,
        onSuccess,
        onError,
        type: '不提示'
      })
    } else {
      clearTimeout(window.zishuTimer)
    }
  }, 60 * 1000)
}

export const getUserInfoApi = async ({ dispatch, CHAT_DEFAULT, onSuccess, onError }: any) => {
  if (!localStorage.getItem('uniToken')) return
  try {
    const res: any = await http('post', apiGroup.userinfo, { unitoken: localStorage.getItem('uniToken') })
    const list = res.data.coupons.find((item: any) => item.type === 1)?.list || []
    const used: any = []
    const all: any = []
    list.forEach((item: any) => {
      all.push(item)
      if (item.use_status == 1) {
        used.push(item)
      }
    })
    used.forEach((item: any) => {
      item.function = 'PPT生成'
    })
    res.data.expand_records = [...res.data.expand_records, ...used].sort((a: any, b: any) => b.created_at - a.created_at)
    res.data.payment_records = [...res.data.payment_records, ...all].sort((a: any, b: any) => b.created_at - a.created_at)
    if (res.status) {
      dispatch({ type: CHAT_DEFAULT, payload: { userInfo: res.data } })
      localStorage.setItem('ai-pager-userInfo', JSON.stringify(res.data))
      if (onSuccess) onSuccess(res.data)
    } else {
      message.error(res?.msg || '请求失败')
      if (onError) onError(res.msg)
    }
  } catch (err) {
    message.error('请求失败!')
    if (onError) onError(err)
    console.log(err)
  }
}

export const setSessionStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  const data = sessionStorage.getItem('lunwenInfo')
  if (data) {
    const lunwenInfo = JSON.parse(data)
    sessionStorage.setItem('lunwenInfo', JSON.stringify({ ...lunwenInfo, ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  } else {
    sessionStorage.setItem('lunwenInfo', JSON.stringify({ ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  }
}
export const getSessionStorage = ({ dispatch, CHAT_DEFAULT }: any) => {
  const data = sessionStorage.getItem('lunwenInfo')
  if (data) {
    const lunwenInfo = JSON.parse(data)
    dispatch({ type: CHAT_DEFAULT, payload: lunwenInfo })
    console.log(JSON.parse(data))
    return JSON.parse(data)
  }
}

export const setLocalStorage = ({ dispatch, CHAT_DEFAULT, aiPaperData, catalog }: any) => {
  aiPaperData[0].catalog = [...catalog]
  aiPaperData[0].time = CommonFunc.timestampToTime(new Date().getTime(), true)
  localStorage.setItem('ai-paper-data', JSON.stringify(aiPaperData))
  dispatch({ type: CHAT_DEFAULT, payload: { aiPaperData: [...aiPaperData] } })
}
export const setLowInfoLocalStorage = ({ dispatch, CHAT_DEFAULT, lowKey, storeKey, list, paragraphNum }: any) => {
  const data: any = JSON.parse(localStorage.getItem(lowKey) || '[]')
  if (paragraphNum) {
    data[0].paragraphNum = paragraphNum
  }
  data[0].list = [...list]
  data[0].time = CommonFunc.timestampToTime(new Date().getTime(), true)
  localStorage.setItem(lowKey, JSON.stringify(data))
  dispatch({ type: CHAT_DEFAULT, payload: { [storeKey]: [...data] } })
}

export const setCreatPPTSessionStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  let creatPPTInfo = JSON.parse(sessionStorage.getItem('creatPPTInfo') || '{}')
  creatPPTInfo = { ...creatPPTInfo, ...payload }
  dispatch({ type: CHAT_DEFAULT, payload: { ...creatPPTInfo } })
  sessionStorage.setItem('creatPPTInfo', JSON.stringify({ ...creatPPTInfo }))
}

export const getCreatPPTSessionStorage = ({ dispatch, CHAT_DEFAULT }: any) => {
  const data = sessionStorage.getItem('creatPPTInfo')
  if (data) {
    const creatPPTInfo = JSON.parse(data)
    dispatch({ type: CHAT_DEFAULT, payload: creatPPTInfo })
    return JSON.parse(data)
  }
}

export const setCreatPPTLocalStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  const data = JSON.parse(localStorage.getItem('creat-ppt-data') || '[]')
  data[0] = { ...data[0], ...payload, time: CommonFunc.timestampToTime(new Date().getTime(), true) }
  localStorage.setItem('creat-ppt-data', JSON.stringify([...data]))
  dispatch({ type: CHAT_DEFAULT, payload: { creatPPTData: [...data] } })
}
export const setInputReportSessionStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  const data = sessionStorage.getItem('inputReportInfo')
  if (data) {
    const inputReportInfo = JSON.parse(data)
    sessionStorage.setItem('inputReportInfo', JSON.stringify({ ...inputReportInfo, ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  } else {
    sessionStorage.setItem('inputReportInfo', JSON.stringify({ ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  }
}
export const getInputReportSessionStorage = ({ dispatch, CHAT_DEFAULT }: any) => {
  const data = sessionStorage.getItem('inputReportInfo')
  if (data) {
    const inputReportInfo = JSON.parse(data)
    dispatch({ type: CHAT_DEFAULT, payload: inputReportInfo })
    return JSON.parse(data)
  }
}
export const setInputReportLocalStorage = ({ dispatch, CHAT_DEFAULT, openReportData, catalog }: any) => {
  openReportData[0].catalog = [...catalog]
  openReportData[0].time = CommonFunc.timestampToTime(new Date().getTime(), true)
  localStorage.setItem('open-report-data', JSON.stringify(openReportData))
  dispatch({ type: CHAT_DEFAULT, payload: { openReportData: [...openReportData] } })
}
