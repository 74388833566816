import { memo, useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext, CHAT_DEFAULT, defaultData, defaultCreatPPTData, defaultOpenReportData } from '@/store/store'
import { Modal, Button, Input, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import BasicSpin from '@/components/BasicSpin/BasicSpin'
import FileLoader from '@/components/FileLoader/FileLoader'
import { getUserInfoApi, setSessionStorage, setCreatPPTSessionStorage, setInputReportSessionStorage } from '@/server/commonFetch'
import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import { DESC } from '@/pages/ThesisTopic/const'
import './LoginModal.less'
const unityUrl = process.env.VITE_NAME
// declare interface LoginModalProps {
//   isModalOpen?: boolean
//   className?: string
//   onOk?: () => void
//   onCancel?: () => void
//   history?: any
// }
const LoginModal = (props: any) => {
  const { dispatch, uniToken } = useContext<any>(ThemeContext)
  const [isModalOpen, setIsModalOpen] = useState(props.isModalOpen)
  const [text, setText] = useState('首次使用')
  const [codeVal, setCodeVal] = useState(props.code)
  const [loading, setLoading] = useState<any>(false)
  useEffect(() => {
    setIsModalOpen(props.isModalOpen)
  }, [props.isModalOpen])
  const handleOk = () => {
    if (props.onOk) {
      props.onOk()
    }
  }
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }
  const importFile = (data: any) => {
    localStorage.setItem('ai-paper-data', JSON.stringify(data.aiPaperData))
    localStorage.setItem('uniToken', data.uniToken)
    localStorage.setItem('ai-pager-userInfo', JSON.stringify(data.userInfo))
    localStorage.setItem('low-chong-data', JSON.stringify(data.lowChongData || []))
    localStorage.setItem('low-aigc-data', JSON.stringify(data.lowAIGCData || []))
    localStorage.setItem('creat-ppt-data', JSON.stringify(data.creatPPTData || []))
    localStorage.setItem('open-report-data', JSON.stringify(data.openReportData || []))
    dispatch({
      type: CHAT_DEFAULT,
      payload: {
        aiPaperData: [...data.aiPaperData],
        userInfo: data.userInfo,
        uniToken: data.uniToken,
        lowChongData: [...data.lowChongData],
        lowAIGCData: [...data.lowAIGCData],
        creatPPTData: [...data.creatPPTData],
        openReportData: [...data.openReportData]
      }
    })
    // props.history.push(unityUrl + '/mainbody')
    if (data.aiPaperData && data.aiPaperData.length > 0) {
      props.history.push(unityUrl + '/mainbody')
      return
    }
    if (data.lowChongData && data.lowChongData.length > 0) {
      props.history.push(unityUrl + '/lowinfo/5')
      return
    }
    if (data.lowAIGCData && data.lowAIGCData.length > 0) {
      props.history.push(unityUrl + '/lowinfo/6')
      return
    }
    if (data.creatPPTData && data.creatPPTData.length > 0) {
      props.history.push(unityUrl + '/resulePPT')
      return
    }
    if (data.openReportData && data.openReportData.length > 0) {
      props.history.push(unityUrl + '/openReportbody')
      return
    }
  }
  const gotoUse = async () => {
    if (text === '首次使用') {
      try {
        const params = codeVal ? { invite_code: codeVal } : {}
        setLoading(true)
        const res: any = await http('post', apiGroup.regist, params)
        setLoading(false)
        if (res.status) {
          dispatch({ type: CHAT_DEFAULT, payload: { uniToken: res.data } })
          localStorage.setItem('uniToken', res.data)
          getUserInfo()
        } else {
          message.error(res.msg)
        }
      } catch (err) {
        console.log(err)
        setLoading(false)
      }
    } else {
      // 双旦
      if (props.type === 'try') {
        if (props.callback) props.callback(props.type)
        return
      }
      // 双旦
      if (props.type === 'buy') {
        if (props.callback) props.callback(props.type)
        return
      }
      // 奖励领取
      if (props.type === 'getjiangli') {
        if (props.callback) props.callback(props.type)
        return
      }
      if (props.type === -1) {
        setIsModalOpen(false)
        return
      }
      if (['课程论文', '毕业论文 (本科)', '毕业论文 (专科)'].includes(props.type)) {
        const obj: any = { 课程论文: 0, '毕业论文 (本科)': 1, '毕业论文 (专科)': 2 }
        setSessionStorage({
          dispatch,
          CHAT_DEFAULT,
          payload: {
            ...defaultData,
            thesisType: obj[props.type],
            lunwenzishu: DESC[obj[props.type]][DESC[obj[props.type]].length - 1].label
          }
        })
        props.history.push(unityUrl + '/thesisTopic')
      } else if (props.type === 'ppt') {
        setCreatPPTSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultCreatPPTData } })
        props.history.push(unityUrl + '/creatPPT')
      } else if (props.type === 5 || props.type === 6) {
        setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultData } })
        props.history.push(unityUrl + '/lowInfo/' + props.type)
      } else if ([0, 1, 2, 3, 4].includes(props.type)) {
        props.history.push(unityUrl + '/paragraphModify?type=' + props.type)
        // 又上角 更多 执行
      } else if (['70', '71', '72', '73'].includes(props.type)) {
        if (props.callback) props.callback(props.type)
      } else if (props.type == 'openreport') {
        setInputReportSessionStorage({
          dispatch,
          CHAT_DEFAULT,
          payload: { ...defaultOpenReportData }
        })
        props.history.push(unityUrl + '/openReportTopic')
      }
    }
  }
  const getUserInfo = () =>
    getUserInfoApi({
      dispatch,
      CHAT_DEFAULT,
      onSuccess: () => setText('进入')
    })
  const inputCode = (e: any) => {
    console.log(e.target.value)
    let value = e.target.value
    // 移除非字母和数字的字符，并将字母转换为大写
    value = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
    if (value.length > 8) {
      value = value.slice(0, 8)
    }
    setCodeVal(value)
  }
  return (
    <>
      <Modal
        wrapClassName={`${props.className || ''} login-modal-container ${text === '进入' && 'login-modal-container-2'}`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        title=''
        footer={null}
        centered
      >
        {loading ? <BasicSpin text='' theme='dark' /> : null}
        <h3>领取身份令牌</h3>
        {text === '进入' ? (
          <>
            <CopyToClipboard text={uniToken} onCopy={() => message.success('UniToken 已复制')}>
              <div style={{ cursor: 'pointer' }}>
                <div className='title1'>
                  <span style={{ fontSize: '.14rem' }}>UniToken：</span>
                  <span style={{ fontSize: '.16rem', fontWeight: 'bold' }}>{uniToken}</span>
                </div>
                <div className='title2 title3' style={{ fontSize: '.12rem', color: '#666' }}>
                  （点击复制）请妥善保存
                </div>
              </div>
            </CopyToClipboard>
          </>
        ) : (
          <>
            <div className='title1'>新用户请点击“首次使用”，领取身份令牌</div>
            <div className='title2'>
              <i className='icon iconfont icon-dunpai' />
              （身份令牌是用户唯一标识，仅用于用户区分，不含任何个人信息）
            </div>
            <Input className='btn' maxLength={8} placeholder='邀请码（选填）' value={codeVal} onChange={inputCode} />
          </>
        )}

        <div className={`btn-container ${text === '进入' ? 'btn-container-2' : ''}`}>
          {text === '进入' ? null : <FileLoader text='导入存档' callback={importFile} desc={'选取 [导出存档] 生成的文件'} />}
          <Button type='primary' onClick={gotoUse}>
            {text}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default memo(withRouter(LoginModal))
