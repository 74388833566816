import { memo, useState, useEffect, useContext, useRef } from 'react'
import { ThemeContext, CHAT_DEFAULT } from '@/store/store'
import { withRouter } from 'react-router-dom'
import { Modal, QRCode, message } from 'antd'
import SvgIcon from '@/components/SvgIcon/SvgIcon'
import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import BasicSpin from '@/components/BasicSpin/BasicSpin'
import { getUserInfoApi } from '@/server/commonFetch'
import './PayModal.less'
const PayModal = (props: any) => {
  const { dispatch } = useContext<any>(ThemeContext)
  const [payInfo] = useState(props.payInfo)
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState('')
  const timer = useRef<any>(null)
  const controller = new AbortController()
  useEffect(() => {
    setLoading(true)
    createCode()
    return () => {
      clearTimeout(timer.current)
    }
  }, [])
  const handleOk = () => {
    if (props.onOk) props.onOk()
  }
  const handleCancel = () => {
    if (controller && typeof controller.abort === 'function') {
      controller.abort()
    }
    clearTimeout(timer.current)
    if (props.onCancel) props.onCancel()
  }
  const createCode = async () => {
    setLoading(true)
    try {
      const res: any = await http('post', payInfo.activity != 1 ? apiGroup.pay : apiGroup.pay_coupon, {
        id: payInfo.id,
        count: payInfo.words,
        unitoken: localStorage.getItem('uniToken')
      })
      if (res.status) {
        setUrl(res.data.code_url)
        setLoading(false)
        payment_statements(res.data.out_trade_no)
      } else {
        message.error(res.msg)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      if (typeof err === 'string') {
        message.error(err)
      }
      setLoading(false)
    }
  }
  const payment_statements = async (out_trade_no: any) => {
    try {
      const res: any = await http('get', apiGroup.payment_statements, { out_trade_no }, controller.signal)
      // console.log(res)
      console.log(payInfo.activity)
      if (res.status && res.data && res.data.trade_state == 'SUCCESS') {
        message.success('支付成功')
        clearTimeout(timer.current)
        getUserInfoApi({ dispatch, CHAT_DEFAULT })
        if (payInfo.words >= 3 && payInfo.activity != 1) {
          payAgain()
        } else {
          if (props.onCancel) props.onCancel()
        }
      } else {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
          payment_statements(out_trade_no)
        }, 2000)
      }
    } catch {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        payment_statements(out_trade_no)
      }, 2000)
    }
  }
  const payAgain = async () => {
    try {
      const res: any = await http('post', apiGroup.pay_coupon, {
        id: payInfo.sendId,
        count: payInfo.activity == 2 ? 1 : Math.floor(payInfo.words / 3),
        unitoken: localStorage.getItem('uniToken')
      })
      if (res.status) {
        message.success('赠送产品已到账')
        getUserInfoApi({ dispatch, CHAT_DEFAULT })
        if (props.onCancel) props.onCancel()
      } else {
        message.error(res.msg)
      }
    } catch (err) {
      console.log(err)
      if (typeof err === 'string') {
        message.error(err)
      }
    }
  }
  const sendFunc = () => {
    // '购买字数'
    if (payInfo.activity == 0) {
      return `${payInfo.words}万字` + (payInfo.words >= 3 ? ` PPT券${Math.floor(payInfo.words / 3)}张` : '')
    }
    // '购买PPT券'
    if (payInfo.activity == 1) {
      return `PPT券${payInfo.words}张`
    }
    if (payInfo.activity == 2) {
      return `${payInfo.words}万字  PPT券1张`
    }
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} pay-modal-container`}
      open={payInfo.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      title=''
      footer={null}
      centered
    >
      <div className='codeInfo'>
        <div className='codeInfo-title'>
          <SvgIcon className='icon-title' href={payInfo.icon} />
          <div className='right'>
            <span>{payInfo.title}</span>
            <span>{sendFunc()}</span>
          </div>
        </div>
        <span className='heji'>合计：{(payInfo.words * payInfo.price).toFixed(2)}元</span>
        <span className='desc'>请使用微信支付</span>
        <div className='wrapper'>
          {loading && <BasicSpin theme='dark' />}
          {url && <QRCode value={url || '-'} />}
        </div>
        <SvgIcon className='icon-wechat' href={`icon-wechat`} />
      </div>
    </Modal>
  )
}

export default memo(withRouter(PayModal))
