import { memo, useState, useEffect, useContext, Fragment } from 'react'
import { ThemeContext, CHAT_DEFAULT } from '@/store/store'
import { withRouter } from 'react-router-dom'
import { Modal, message, Button } from 'antd'
import SvgIcon from '@/components/SvgIcon/SvgIcon'
import PayModal from '@/components/PayModal/PayModal'
import BaseCountDown from '@/components/BaseCountDown/BaseCountDown'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getUserInfoApi } from '@/server/commonFetch'
import { data, data1 } from './const'
import './BuyModal.less'
const XIANGOU_NUM = 5
const BuyModal = (props: any) => {
  const { dispatch, userInfo = {} } = useContext<any>(ThemeContext)
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  const [isXianGou, setIsXianGou] = useState(0)
  const [words, setWords] = useState<any>({ 0: 1, 1: 1 })
  const [payInfo, setPayInfo] = useState({ visible: false })
  const [days, setDays] = useState([])
  const [countDownEnd, setCountDownEnd] = useState(false)
  const userType = userInfo.invite_code ? 'inviteUser' : 'generalUser'
  const controller = new AbortController()
  // 双旦活动代码 end
  useEffect(() => {
    getUserInfoApi({
      dispatch,
      CHAT_DEFAULT,
      onSuccess: (data: any) => {
        const length = data?.payment_records?.filter((item: any) => item.cid == 202)?.length
        setIsXianGou(length)
      }
    })
  }, [])
  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) props.onOk()
  }
  const handleCancel = () => {
    if (controller && typeof controller.abort === 'function') {
      controller.abort()
    }
    if (props.onCancel) props.onCancel()
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} buyModal1-modal-container`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      title=''
      footer={null}
      centered
    >
      <header>
        <div className='left'>
          <div className='buy-modal-title'>
            <SvgIcon className='icon-chongzhi' href={`icon-chongzhi`} />
            <span>充值中心</span>
          </div>
        </div>
        <div className='right'>
          <CopyToClipboard text={localStorage.getItem('uniToken')} onCopy={() => message.success('UniToken 已复制')}>
            <div className='token'>
              <span>UniToken：</span>
              <label>{localStorage.getItem('uniToken')}</label>
            </div>
          </CopyToClipboard>
          <div className='words'>
            <span>剩余字数：</span>
            <label>{userInfo.sum_words - userInfo.expend_words >= 0 ? userInfo.sum_words - userInfo.expend_words : 0} 字</label>
          </div>
          <div className='words'>
            <span>剩余PPT券：</span>
            <label>{userInfo.coupons?.find((item: any) => item.type == 1)?.ppt_coupons_count || 0} 张</label>
          </div>
        </div>
      </header>
      <main>
        {data.map((item: any, index: number) => {
          return (
            <div className='item' key={index}>
              <div className='title'>
                <SvgIcon className='icon-title' href={item.icon} />
                <span>{item.title}</span>
              </div>
              <div className='content'>
                {item.content.map((itm: any, index: number) => {
                  return (
                    <div key={index} className='content-item'>
                      <i className='icon iconfont icon-gouxuan' />
                      <span>{itm}</span>
                    </div>
                  )
                })}
              </div>
              <div className='desc'>
                {item.desc.map((itm: any, index: number) => {
                  return (
                    <div key={index} className='desc-item'>
                      <SvgIcon className='icon-title' href={'icon-a-renzhenggerenrenzheng'} />
                      <div className='desc-content'>
                        <span className='desc-title'>{itm.title}</span>
                        <span className='desc-sub-title'>{itm.subTitle}</span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='price'>
                <span className='name'>单价：</span>
                <span className='yuan'>
                  {item[userType].price}元<label>/{item.unit}</label>
                </span>
                <label className='orgin-price'>
                  {item.originPrice}元/{item.unit}
                </label>
                <p className={`tip ${userType === 'inviteUser' ? 'tip2' : ''}`}>{item[userType].title}</p>
              </div>
              <div className={`buyInfo ${index == 1 ? 'buyInfo2' : ''}`}>
                <i
                  className={`icon iconfont icon-heifangkuaijian doicon`}
                  onClick={() => {
                    if (words[index] === 1) return
                    words[index] = words[index] > 0 ? words[index] - 1 : 0
                    setWords(JSON.parse(JSON.stringify(words)))
                  }}
                />
                <div className='text'>
                  <span>{words[index]}</span>
                  <span>{item.unit}</span>
                </div>
                <i
                  className={`icon iconfont icon-heifangkuaijia doicon`}
                  onClick={() => {
                    words[index] = words[index] + 1
                    setWords(JSON.parse(JSON.stringify(words)))
                  }}
                />
                {index == 0 ? (
                  <div className='zengsong'>
                    {words[index] >= 3 ? (
                      <>
                        <span>赠送</span>
                        <label>PPT券{Math.floor(words[index] / 3)}张</label>
                      </>
                    ) : (
                      <span className='sendTitle'>{item[userType].sendTitle}</span>
                    )}
                  </div>
                ) : null}
              </div>
              <Button
                type='primary'
                onClick={() =>
                  setPayInfo({
                    visible: true,
                    ...item[userType],
                    title: item.title,
                    icon: item.icon,
                    words: words[index],
                    activity: item.activity
                  })
                }
              >
                立即购买
              </Button>
            </div>
          )
        })}
        <div className='item item2'>
          <BaseCountDown
            className='countDown-container'
            onCountDownEnd={() => setCountDownEnd(true)}
            onSetDate={(ds: any) => setDays(ds)}
          />
          <div className='title'>
            <SvgIcon className='icon-title' href={data1.icon} />
            <span>{data1.title}</span>
          </div>
          <div className='desc'>
            {data1.list.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <div className='list-item' key={index}>
                    {item.desc.map((itm: any, index: number) => {
                      return (
                        <div key={index} className='desc-item'>
                          <SvgIcon className='icon-title' href={'icon-a-renzhenggerenrenzheng'} />
                          <div className='desc-content'>
                            <span className='desc-title'>
                              {itm.title1 && itm.title1}
                              {itm.title2 && <span style={{ fontSize: '0.2rem' }}>{itm.title2}</span>}
                              {itm.title3 && itm.title3}
                            </span>
                            <span className='desc-sub-title'>
                              {itm.subTitle1 && itm.subTitle1}
                              {itm.subTitle2 && <span style={{ fontWeight: 600, fontSize: '0.12rem' }}>{itm.subTitle2}</span>}
                              {itm.subTitle3 && itm.subTitle3}
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <Button
                    type='primary'
                    className={`${index == 0 ? 'buy buy-btn' : 'buy-btn'} ${
                      countDownEnd || (index == 1 && isXianGou > XIANGOU_NUM) ? 'disabled-btn' : ''
                    }`}
                    disabled={countDownEnd || (index == 1 && isXianGou > XIANGOU_NUM) ? true : false}
                    onClick={() =>
                      setPayInfo({
                        visible: true,
                        ...item[userType],
                        title: data1.title,
                        icon: data1.icon,
                        words: item.words,
                        activity: item.activity
                      })
                    }
                  >
                    {item.btn}
                    {index == 1 && <div className='xiangou-tip'>限购{XIANGOU_NUM}次</div>}
                  </Button>
                </Fragment>
              )
            })}
          </div>
          <span className='item2-desc'>{data1.desc}</span>
          <span className='item2-time'>{data1.time.replace('start', days[0]).replace('end', days[1])}</span>
        </div>
      </main>
      {payInfo.visible && <PayModal payInfo={payInfo} onCancel={() => setPayInfo({ visible: false })} />}
    </Modal>
  )
}

export default memo(withRouter(BuyModal))
