/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo, useState, useEffect, useContext } from 'react'
import { ThemeContext, CHAT_DEFAULT } from '@/store/store'
import Title from '../Title'
import { getSessionStorage, getCreatPPTSessionStorage, getInputReportSessionStorage } from '@/server/commonFetch'
import { getUserInfoApi } from '@/server/commonFetch'
import Thinks from '../Thinks/Thinks'
import './index.less'
const unityUrl = process.env.VITE_NAME
const Home = (props: any) => {
  const { dispatch, aiPaperData = [], openReportData = [] } = useContext<any>(ThemeContext)
  const [loading, setLoading] = useState(true)

  const getUserInfo = () => getUserInfoApi({ dispatch, CHAT_DEFAULT })
  useEffect(() => {
    const getData = async () => {
      await getUserInfo()
      // const aiPaperData = localStorage.getItem('ai-paper-data') || '[]'
      // const lowChongData = localStorage.getItem('low-chong-data') || '[]'
      // const lowAIGCData = localStorage.getItem('low-aigc-data') || '[]'
      // const creatPPTData = localStorage.getItem('creat-ppt-data') || '[]'
      // const openReportData = localStorage.getItem('open-report-data') || '[]'
      // if (openReportData) {
      //   const data = JSON.parse(openReportData)
      //   dispatch({ type: CHAT_DEFAULT, payload: { openReportData: [...data] } })
      // }
      // if (aiPaperData) {
      //   const data = JSON.parse(aiPaperData)
      //   dispatch({ type: CHAT_DEFAULT, payload: { aiPaperData: [...data] } })
      // }
      // if (lowChongData) {
      //   const data = JSON.parse(lowChongData)
      //   dispatch({ type: CHAT_DEFAULT, payload: { lowChongData: [...data] } })
      // }
      // if (lowAIGCData) {
      //   const data = JSON.parse(lowAIGCData)
      //   dispatch({ type: CHAT_DEFAULT, payload: { lowAIGCData: [...data] } })
      // }
      // if (creatPPTData) {
      //   const data = JSON.parse(creatPPTData)
      //   dispatch({ type: CHAT_DEFAULT, payload: { creatPPTData: [...data] } })
      // }
      if (location.pathname === unityUrl + '/paragraphModify') {
        setLoading(false)
        return
      }
      // 降重/降aigc
      if (location.pathname.includes(unityUrl + '/lowInfo')) {
        setLoading(false)
        return
      }
      // ppt
      if (location.pathname.includes(unityUrl + '/creatPPT')) {
        setLoading(false)
        return
      }
      // ppt result
      if (location.pathname.includes(unityUrl + '/resulePPT')) {
        setLoading(false)
        return
      }
      // 开题报告
      if (location.pathname.includes('openReport')) {
        const inputReportCacheInfo = getInputReportSessionStorage({ dispatch, CHAT_DEFAULT })
        if (openReportData && inputReportCacheInfo) {
          if (location.pathname.toLocaleLowerCase() === (unityUrl + '/openReportbody').toLocaleLowerCase()) {
            setLoading(false)
            return
          }
          if (location.pathname === unityUrl + '/openReportReferences') {
            if (inputReportCacheInfo.ansewrDate && inputReportCacheInfo.stageData.findIndex((item: any) => item.checked) >= 0) {
              setLoading(false)
              return
            }
            if (inputReportCacheInfo.titleData && inputReportCacheInfo.titleData.length && inputReportCacheInfo.title) {
              setLoading(false)
              props.history.push(unityUrl + '/openReportPlans')
              return
            }
            setLoading(false)
            props.history.push(unityUrl + '/openReportTopic')
            return
          }
          if (location.pathname === unityUrl + '/openReportPlans') {
            if (inputReportCacheInfo.titleData && inputReportCacheInfo.titleData.length) {
              setLoading(false)
              return
            }
            setLoading(false)
            props.history.push(unityUrl + '/openReportTopic')
            return
          }
          if (location.pathname === unityUrl + '/openReportTopic') {
            setLoading(false)
            return
          }
        } else if (openReportData && !inputReportCacheInfo) {
          if (location.pathname !== unityUrl + '/openReportbody') {
            setLoading(false)
            props.history.push(unityUrl + '/openReportTopic')
            return
          }
          setLoading(false)
        } else if (!openReportData && inputReportCacheInfo) {
          if (location.pathname.toLocaleLowerCase() === (unityUrl + '/openReportbody').toLocaleLowerCase()) {
            if (inputReportCacheInfo.ansewrDate && inputReportCacheInfo.stageData.findIndex((item: any) => item.checked) >= 0) {
              setLoading(false)
              props.history.push(unityUrl + '/openReportReferences')
              return
            }
            if (inputReportCacheInfo.titleData && inputReportCacheInfo.titleData.length && inputReportCacheInfo.title) {
              setLoading(false)
              props.history.push(unityUrl + '/openReportPlans')
              return
            }
            setLoading(false)
            props.history.push(unityUrl + '/openReportTopic')
            return
          }
          if (location.pathname === unityUrl + '/openReportReferences') {
            if (inputReportCacheInfo.ansewrDate && inputReportCacheInfo.stageData.findIndex((item: any) => item.checked) >= 0) {
              setLoading(false)
              return
            }
            if (inputReportCacheInfo.titleData && inputReportCacheInfo.titleData.length) {
              setLoading(false)
              props.history.push(unityUrl + '/openReportPlans')
              return
            }
            setLoading(false)
            props.history.push(unityUrl + '/openReportTopic')
            return
          }
          if (location.pathname === unityUrl + '/openReportPlans') {
            if (inputReportCacheInfo.titleData && inputReportCacheInfo.titleData.length) {
              setLoading(false)
              return
            }
            setLoading(false)
            props.history.push(unityUrl + '/openReportTopic')
            return
          }
          if (location.pathname === unityUrl + '/openReportTopic') {
            setLoading(false)
            return
          }
        } else {
          setLoading(false)
          props.history.push(unityUrl + '/openReportTopic')
        }
        return
      }
      const cacheInfo = getSessionStorage({ dispatch, CHAT_DEFAULT })
      getCreatPPTSessionStorage({ dispatch, CHAT_DEFAULT })
      // 正文
      if (aiPaperData && cacheInfo) {
        if (location.pathname.toLocaleLowerCase() === (unityUrl + '/mainbody').toLocaleLowerCase()) {
          setLoading(false)
          return
        }
        if (location.pathname === unityUrl + '/references') {
          if (cacheInfo.major) {
            setLoading(false)
            return
          }
          if (cacheInfo.titleData && cacheInfo.titleData.length && cacheInfo.title) {
            setLoading(false)
            props.history.push(unityUrl + '/major')
            return
          }
          setLoading(false)
          props.history.push(unityUrl + '/thesisTopic')
          return
        }
        if (location.pathname === unityUrl + '/major') {
          if (cacheInfo.titleData && cacheInfo.titleData.length) {
            setLoading(false)
            return
          }
          setLoading(false)
          props.history.push(unityUrl + '/thesisTopic')
          return
        }
        if (location.pathname === unityUrl + '/thesisTopic') {
          setLoading(false)
          return
        }
      } else if (aiPaperData && !cacheInfo) {
        if (location.pathname !== unityUrl + '/mainbody') {
          setLoading(false)
          props.history.push(unityUrl + '/thesisTopic')
          return
        }
        setLoading(false)
      } else if (!aiPaperData && cacheInfo) {
        if (location.pathname.toLocaleLowerCase() === (unityUrl + '/mainbody').toLocaleLowerCase()) {
          if (cacheInfo.major) {
            setLoading(false)
            props.history.push(unityUrl + '/references')
            return
          }
          if (cacheInfo.titleData && cacheInfo.titleData.length && cacheInfo.title) {
            setLoading(false)
            props.history.push(unityUrl + '/major')
            return
          }
          setLoading(false)
          props.history.push(unityUrl + '/thesisTopic')
          return
        }
        if (location.pathname === unityUrl + '/references') {
          if (cacheInfo.major) {
            setLoading(false)
            return
          }
          if (cacheInfo.titleData && cacheInfo.titleData.length) {
            setLoading(false)
            props.history.push(unityUrl + '/major')
            return
          }
          setLoading(false)
          props.history.push(unityUrl + '/thesisTopic')
          return
        }
        if (location.pathname === unityUrl + '/major') {
          if (cacheInfo.titleData && cacheInfo.titleData.length) {
            setLoading(false)
            return
          }
          setLoading(false)
          props.history.push(unityUrl + '/thesisTopic')
          return
        }
        if (location.pathname === unityUrl + '/thesisTopic') {
          setLoading(false)
          return
        }
      } else {
        setLoading(false)
        props.history.push(unityUrl + '/thesisTopic')
      }
    }

    getData()
  }, [])
  return (
    <>
      <div className='home-container'>
        <Title />
        {loading ? null : <section>{props.children}</section>}
        <Thinks />
      </div>
    </>
  )
}

export default memo(Home)
